import { useStaticQuery, graphql } from 'gatsby'

const PageQuery = () => {
  const pageQuery = useStaticQuery(graphql`
    query {
      cartaoInter: imageSharp(fluid: {originalName: { regex: "/use-cartao-inter/" }}) {
        fluid(maxWidth: 480, quality: 90) {
          ...GatsbyImageSharpFluid
        }
      }
      appLimiteCartao: imageSharp(fluid: {originalName: { regex: "/app-limite-cartao/" }}) {
        fluid(maxWidth: 354, quality: 90) {
          ...GatsbyImageSharpFluid
        }
      }
      carteiraDigital: imageSharp(fluid: {originalName: { regex: "/carteira-digital/" }}) {
        fluid(maxWidth: 354, quality: 90) {
          ...GatsbyImageSharpFluid
        }
      }
      backgroundCashbackMobile: imageSharp(fluid: {originalName: { regex: "/cashback-ao-pagar-fatura-bg-mobile/" }}) {
        fluid(maxWidth: 414, quality: 90) {
          ...GatsbyImageSharpFluid
        }
      }
      backgroundCashbackMD: imageSharp(fluid: {originalName: { regex: "/cashback-ao-pagar-fatura-bg-md/" }}) {
        fluid(maxWidth: 490, quality: 90) {
          ...GatsbyImageSharpFluid
        }
      }
      backgroundCashbackLG: imageSharp(fluid: {originalName: { regex: "/cashback-ao-pagar-fatura-bg-lg/" }}) {
        fluid(maxWidth: 360, quality: 90) {
          ...GatsbyImageSharpFluid
        }
      }
      backgroundCashbackXL: imageSharp(fluid: {originalName: { regex: "/cashback-ao-pagar-fatura-bg-xl/" }}) {
        fluid(maxWidth: 732, quality: 90) {
          ...GatsbyImageSharpFluid
        }
      }
      cartaoVirtual: imageSharp(fluid: {originalName: { regex: "/cartao-virtual/" }}) {
        fluid(maxWidth: 492, quality: 90) {
          ...GatsbyImageSharpFluid
        }
      }
      goldCard: imageSharp(fluid: {originalName: { regex: "/cartao-inter-gold/" }}) {
        fluid(maxWidth: 534, quality: 90) {
          ...GatsbyImageSharpFluid
        }
      }
      platinumCard: imageSharp(fluid: {originalName: { regex: "/cartao-inter-platinum/" }}) {
        fluid(maxWidth: 534, quality: 90) {
          ...GatsbyImageSharpFluid
        }
      }
      beneficiosCartaoInterBlackCard: imageSharp(fluid: {originalName: { regex: "/beneficios-cartao-inter-black/" }}) {
        fluid(maxWidth: 534, quality: 90) {
          ...GatsbyImageSharpFluid
        }
      }
      winCard: imageSharp(fluid: {originalName: { regex: "/cartao-inter-win/" }}) {
        fluid(maxWidth: 534, quality: 90) {
          ...GatsbyImageSharpFluid
        }
      }
      apllePay: imageSharp(fluid: {originalName: { regex: "/logo-aplle-pay/" }}) {
        fluid(maxWidth: 172, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
      googlePay: imageSharp(fluid: {originalName: { regex: "/logo-google-pay/" }}) {
        fluid(maxWidth: 172, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
      facebookPay: imageSharp(fluid: {originalName: { regex: "/logo-facebook-pay/" }}) {
        fluid(maxWidth: 172, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
      whatsAppPay: imageSharp(fluid: {originalName: { regex: "/logo-whatsapp-pay-cartoes/" }}) {
        fluid(maxWidth: 172, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
      pay: imageSharp(fluid: {originalName: { regex: "/logo-samsung-pay-cartoes/" }}) {
        fluid(maxWidth: 172, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  `)

  return pageQuery
}

export default PageQuery
