import styled from 'styled-components'
import { device } from 'src/styles/breakpoints'

import bgImageMobile from './../../assets/images/ativacao-cliente-outro-motivo-mobile.png'
import bgImageMd from './../../assets/images/ativacao-cliente-outro-motivo-md.png'
import bgImageLg from './../../assets/images/ativacao-cliente-outro-motivol-lg.png'
import bgImageXl from './../../assets/images/ativacao-cliente-outro-motivo-xl.png'

export const Section = styled.section`
  min-height: 542px;
  padding-bottom: 48px;

  @media ${device.tablet} {
    background-image: url(${bgImageMd});
    background-repeat: no-repeat;
    background-position: center right;
    padding-bottom: 0;
  }

  @media ${device.desktopLG} {
    background-image: url(${bgImageLg});
    background-repeat: no-repeat;
    background-position: center right;
  }

  @media ${device.desktopXL} {
    background-image: url(${bgImageXl});
    background-repeat: no-repeat;
    background-position: center right;
  }

  .btn {
    padding-top: 7px;

    @media ${device.tablet} {
      max-width: 340px;
    }  
    @media ${device.desktopLG} {
      max-width: 385px;
    }
    @media ${device.desktopXL} {
      max-width: 470px;
    }  
  }
`

export const BgSection = styled.div`
  height: 312px;
  width: 100%;
  text-align: center;
  background-image: url(${bgImageMobile});
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
`
