import React from 'react'
import Img from 'gatsby-image'

// Hooks
import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'

import OrangeIcon from 'src/components/UI/MarkdownIcon/OrangeDsIcon'
import Tab from 'src/components/Tab'

import AdvantagesOfInterCardsJSON from '../../assets/data/AdvantagesOfInterCards.json'

import usePageQuery from './pageQuery'
import Check from './../../assets/images/check.png'

import { Benefits } from './style'
import { DataProps, SpotProps } from './_types'

const Beneficios = () => {
  const [ sendDatalayerEvent ] = useDataLayer()
  const data = usePageQuery()
  const tabName = AdvantagesOfInterCardsJSON.map((item: DataProps) => item.card)

  return (
    <Benefits id='beneficios'>
      <div className='container pt-1 py-5'>
        <h3 className='fs-24 fs-md-32 fs-lg-40 lh-30 lh-md-40 lh-lg-50 text-md-center text-grayscale--500 px-md-5'>
          <span className='d-lg-block'>Com todas as vantagens dos</span> cartões Inter Mastercard.
        </h3>
        <p className='fs-14 fs-md-18 lh-17 lh-md-22 text-md-center text-grayscale--400'>
          <span className='d-lg-block'>Os cartões Mastercard oferecem segurança e flexibilidade nos seus pagamentos,</span> combinando benefícios, vantagens e promoções para facilitar seu dia a dia.
        </p>
        <Tab tabDifColor classDiv='mt-5' items={tabName} styles='fs-14 fs-md-24'>
          {AdvantagesOfInterCardsJSON.map((card: DataProps, index: number) => (
            <div key={index} className='col-12 mt-5'>
              <div className='row align-items-start'>
                <div className='col-12 col-lg-5 order-lg-last d-flex justify-content-center'>
                  <Img className='card-image' fluid={data[card.image].fluid} alt='Imagem card' />
                </div>
                <div className='col-lg-7'>
                  <div className='row'>
                    {card.benefits.map((benefit: string) => (
                      <div key={benefit} className='col-md-6 d-flex align-items-center'>
                        <div className='icon-circle d-flex justify-content-center align-items-center mr-md-3'>
                          <img src={Check} alt='Check' width='29' height='29' />
                        </div>
                        <p
                          className='fs-16 lh-20 text-grayscale--500 font-sora fw-600 mt-3 ml-2 ml-md-2'
                          dangerouslySetInnerHTML={{ __html: benefit }}
                        />
                      </div>
                    ))}
                  </div>
                  <div className='mt-4 row'>
                    {card.descriptionSpot.map(({ icon, text, hasLink }: SpotProps, index: number) => (
                      <div key={index} className='d-flex align-items-center align-items-md-start col-md-12 col-xl-11 pt-md-3 pb-md-1 px-md-2 pr-lg-4'>
                        {icon &&
                          <div className='mr-2 mr-md-3 mr-lg-3'>
                            <OrangeIcon
                              size='LG'
                              color='#FF8700'
                              icon={icon}
                            />
                          </div>
                        }
                        <p
                          onClick={() => sendDatalayerEvent({
                            section: 'dobra_7',
                            section_name: 'Com todas as vantagens dos cartões Inter Mastercard.',
                            element_action: 'click button',
                            element_name: 'Saiba mais',
                            redirect_url: hasLink,
                          })}
                          className='fs-16 lh-19 text-grayscale--400 fw-100 mt-4 mt-md-0 ml-md-1 ml-lg-0 mb-lg-4'
                          dangerouslySetInnerHTML={{ __html: text }}
                        />
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          ))}
        </Tab>
      </div>
    </Benefits>
  )
}

export default Beneficios
