import React, { useState } from 'react'

// components
import Layout from 'src/layouts/BaseLayout'
import { Modal } from 'src/components/Modal'
import AtivacaoModal from 'src/components/UI/Forms/InterAtivacaoClientesForm'

// Sections
import Header from '../nao-consigo-acessar-a-minha-conta/sections/contaPraGente/_index'
import SentimosMuito from '../nao-consigo-acessar-a-minha-conta/sections/sentimosMuito/_index'
import ContaDigitalCompleta from '../nao-consigo-acessar-a-minha-conta/sections/contaDigitalCompleta/_index'
import Beneficios from '../nao-consigo-acessar-a-minha-conta/sections/beneficios/_index'
import OfertasExclusivas from '../nao-consigo-acessar-a-minha-conta/sections/ofertasExclusivas/_index'
import HelpCenter from '../helpCenter/_index'

// hooks
import useDomReady from 'src/hooks/window/useDomReady'

import pageContext from './pageContext.json'
import { Wrapper } from './style'

const OutroMotivo = () => {
  const domReady = useDomReady()
  const [ openModal, setOpenModal ] = useState(false)

  const openAccountPJFormModal = domReady && (
    <Modal isModalOpen={openModal} setIsModalOpen={setOpenModal} locationToRender={document.body}>
      <AtivacaoModal closeModal={() => setOpenModal(false)} />
    </Modal>
  )
    return (
      <Wrapper>
        <Layout pageContext={pageContext}>
          {openAccountPJFormModal}
          <Header section='dobra_01' />
          <SentimosMuito />
          <ContaDigitalCompleta />
          <Beneficios />
          <OfertasExclusivas setOpenModal={setOpenModal} />
          <HelpCenter />
        </Layout>
      </Wrapper>
    )
}

export default OutroMotivo
