import styled from 'styled-components'
import { device } from 'src/styles/breakpoints'
import { orange } from 'src/styles/colors'

export const Section = styled.section`
  background-color: ${orange.extra};

  @media ${device.tablet} {
    min-height: 240px;
  }
  @media ${device.desktopLG} {
    min-height: 378px;
  }

  @media ${device.desktopXL} {
    min-height: 392px;
  }

  &.color-green {
    background: #07605B;
  }
`
