import React from 'react'

import { Section, BgSection } from './style'

// Components
import useWidth from 'src/hooks/window/useWidth'
import { sendDataLayerEvent } from 'src/shared/helpers'
import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'

const WIDTH_MD = 768
const WIDTH_SM = 578

type modalProps = {
  setOpenModal: Function;
}

const OfertasExclusivas = ({ setOpenModal }: modalProps) => {
  const [ sendDatalayerEvent ] = useDataLayer()
 const windowWidth = useWidth(300)
 return (
   <Section className='d-md-flex position-relative align-items-center justify-content-md-start'>
     { windowWidth < WIDTH_MD && <BgSection /> }
     <div className='container'>
       <div className='row align-items-center'>
         <div className='col-12 col-md-6 col-lg-5 pr-0 pl-2'>
           <h2 className='fs-24 fs-md-32 fs-lg-40 lh-30 lh-md-40 lh-lg-50 text-grayscale--500 mt-5 mb-4 mt-md-0'>
             Ofertas <span className='d-lg-block d-xl-inline'>exclusivas </span><span className='d-xl-block'>Inter Shop</span>
           </h2>
           <p className='fs-16 fs-md-18 lh-19 lh-md-22 text-grayscale--400 mb-4'>
             Reative sua conta agora e confira todas as ofertas do Inter Shop no nosso aplicativo! E o melhor: você ainda ganha cashback.
           </p>
         </div>
         <div
           className='col-12  d-md-flex justify-content-md-left pt-4 px-0 pl-md-2 pl-xl-0'
           onClick={() => sendDatalayerEvent({
            section: 'dobra_8',
            section_name: 'Ofertas exclusivas Inter Shop',
            element_action: 'click button',
            element_name: 'Acessar minha conta',
            c_page: window.location.href,
          })}
         >
           {windowWidth <= WIDTH_SM
            ? <a href='bancointer://marketplace' className='btn btn-orange--extra btn--lg rounded-2 fs-14 fw-600 text-none'>Acessar minha conta</a>
            : <button className='btn btn-orange--extra btn--lg rounded-2 fs-14 fw-600 text-none' onClick={() => setOpenModal(true)}>Acessar minha conta</button>}
         </div>
       </div>
     </div>
   </Section>
 )
}

export default OfertasExclusivas
