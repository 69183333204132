import React from 'react'

// Data motivos
import textPF from '../../../nao-consigo-acessar-a-minha-conta/assets/data/sentimos-muito-pf.json'
import textMEI from '../../../nao-consigo-acessar-a-minha-conta/assets/data/sentimos-muito-mei.json'
import textPJ from '../../../nao-consigo-acessar-a-minha-conta/assets/data/sentimos-muito-pj.json'

import { Section } from './style'

const SentimosMuito = (props: { page: string }) => {
  let pageText = textPF
  let color = ''

  if (props.page === 'MEI') {
    pageText = textMEI
  } else if (props.page === 'PJ') {
    pageText = textPJ
    color = 'color-green'
  }

  return (
    <Section className={`py-5 d-flex position-relative align-items-center justify-content-md-between ${color}`}>
      <div className='container'>
        <div className='row align-items-center justify-content-md-between'>
          <div className='col-12 col-md-6 col-lg-5 pb-4 pb-md-0'>
            <h2
              className='fs-32 fs-lg-40 lh-40 lh-lg-50 text-grayscale--100 mb-md-0'
              dangerouslySetInnerHTML={{ __html: pageText.title }}
            />
          </div>
          <div className='col-12 col-md-6 col-xl-5'>
            <p
              className='fs-16 fs-md-18 lh-20 lh-md-22 mb-0 text-grayscale--100 mb-md-0'
              dangerouslySetInnerHTML={{ __html: pageText.description }}
            />
          </div>
        </div>
      </div>
    </Section>
  )
}

SentimosMuito.defaultProps = {
  page: 'PF',
}

export default SentimosMuito
